export const HEIGHT_POINTS = {
    "1": 1.6,
    "2": 1.9,
    "3": 2.1,
    "4": 2.3,
    "5": 2.6,
}

export const START_DATE = 'startDate'
export const END_DATE = 'endDate'

export const LANGUAGE_LOCALSTORAGE_KEY = 'userLanguage'
export const CURRENCY_LOCALSTORAGE_KEY = 'userCurrency'

export const TIMES_FOR_LATER_CHECK = [
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
]

export const RESERVATION_MIN_HOURS = 6

// @ts-ignore: Property 'env' does not exist on type 'ImportMeta'.
export const API_KEY = import.meta.env.VITE_API_KEY
// @ts-ignore: Property 'env' does not exist on type 'ImportMeta'.
export const API_VERSION = import.meta.env.VITE_API_VERSION
