import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getLanguage } from './language'
import { en } from './locales/en'
import { cs } from './locales/cs'
import { de } from './locales/de'
import { pl } from './locales/pl'

const initialLanguage = getLanguage()

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        lng: initialLanguage,
        fallbackLng: ['cs', 'en'],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: { ...en },
            cs: { ...cs },
            de: { ...de },
            pl: { ...pl },
        }
    })

export default i18n
