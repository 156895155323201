import { LANGUAGE_LOCALSTORAGE_KEY } from './constants'

export function getLanguage() {
    const htmlLang = document.documentElement.lang

    if (htmlLang) return htmlLang

    const localStorageLang = localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY)?.replace(/"/g, '')

    if (localStorageLang) return localStorageLang

    // @ts-ignore - Legacy compatibility
    const fullLanguage = navigator.language || navigator.userLanguage
    const languageCode = fullLanguage.split('-')[0]

    const ALLOWED_LANGUAGES = ['cs', 'en', 'de', 'pl']

    if (ALLOWED_LANGUAGES.find(item => item === languageCode)) {
        return languageCode
    }

    return 'en'
}
